import { faArrowRightArrowLeft as falArrowRightArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { Typography } from "@mui/material";
import { memo, useCallback } from "react";

export interface MigrationAvailableDialogProps {
  open: boolean;
  postponeable: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const MigrationAvailableDialog = memo<MigrationAvailableDialogProps>(
  ({ open, onClose, onConfirm, postponeable }) => {
    const onModalClose = useCallback(() => {
      if (postponeable) {
        onClose();
      }
    }, [onClose, postponeable]);
    return (
      <Modal
        disableEscapeKeyDown={!postponeable}
        onClose={onModalClose}
        open={open}
      >
        <ModalDialog>
          <DialogTitle
            sx={{
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FontAwesomeIcon
              fixedWidth={true}
              icon={falArrowRightArrowLeft}
              size="3x"
            />
            Migration Available
          </DialogTitle>
          <DialogContent>
            <Stack
              alignItems="flex-start"
              direction="column"
              textAlign="initial"
              width={800}
            >
              <Typography>
                We are changing the authentication and secrets management to be
                able to provide you with the latest features.
              </Typography>
              <Typography>After the migration:</Typography>
              <ul>
                <li>
                  You must have access to your email account used as user name
                  to avoid being locked out of your account.
                </li>
                <li>
                  If you use the SDK, you will have to: Install the latest SDK,
                  create new API tokens, and make small code adaptations.
                </li>
              </ul>
              <Typography>
                You can learn more{" "}
                <Link
                  href="https://docs.decentriq.com/migrating-to-v4"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </Link>
                .
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ flexDirection: "column" }}>
            <Button
              color="primary"
              loadingIndicator="Migrating..."
              onClick={onConfirm}
              variant="solid"
            >
              Yes, migrate
            </Button>
            {postponeable && <Button onClick={onClose}>Not now</Button>}
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);

MigrationAvailableDialog.displayName = "MigrationAvailableDialog";
export default MigrationAvailableDialog;
