import { differenceInCalendarDays } from "date-fns";
import { useSnackbar } from "notistack";
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { KeychainStatus } from "services";
import { EnclaveMfaTokenDialog } from "components";
import { MigrationStatus, useApiCore } from "contexts";
import { useEnclaveTokenStorage, useKeychainSetup } from "wrappers";
import {
  MigrationAvailableDialog,
  MigrationProgressDialog,
  MigrationRequiredDialog,
} from "./components";
import { useKeychainMigration } from "./hooks";

/* State machine for keychain migration:
 * Starting states: NoMigration, PromptMigration, MigrationInProgress
 * NoMigration: No migration required
 *
 * PromptMigration: -> Yes -> MFAPrompt -> MFA successful -> MigrationInProgress -> [close] + success popup
 *                  -> No -> MigrationRequiredWarning -> [close]
 */
const enum KeychainMigrationState {
  NoMigration = "NoMigration",
  PromptMigration = "PromptMigration",
  MigrationRequiredWarning = "MigrationRequiredWarning",
  MFAPrompt = "MFAPrompt",
  MigrationInProgress = "MigrationInProgress",
}

const upstreamMigrationStatusToMigrationState: Record<
  MigrationStatus,
  KeychainMigrationState
> = {
  [MigrationStatus.Migrated]: KeychainMigrationState.NoMigration,
  [MigrationStatus.NoMigration]: KeychainMigrationState.NoMigration,
  [MigrationStatus.PromptMigration]: KeychainMigrationState.PromptMigration,
  [MigrationStatus.MigrationInProgress]:
    KeychainMigrationState.MigrationInProgress,
};

const KeychainMigrationWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    migrationStatus: upstreamMigrationStatus,
    setMigrationStatus: setUpstreamMigrationStatus,
    migrationDeadline,
  } = useApiCore();
  const { progress, migrate } = useKeychainMigration();
  const [keychainMigrationState, setKeychainMigrationState] =
    useState<KeychainMigrationState>(KeychainMigrationState.NoMigration);

  const { state: enclaveToken, setState: setEnclaveToken } =
    useEnclaveTokenStorage();

  const startOrResumeMigration = useCallback(async () => {
    await migrate();
    setKeychainMigrationState(KeychainMigrationState.NoMigration);
    enqueueSnackbar("Keychain migration completed successfully.", {
      persist: true,
      variant: "success",
    });
  }, [enqueueSnackbar, migrate]);
  const { status: keychainStatus } = useKeychainSetup();

  useEffect(() => {
    if (keychainStatus === KeychainStatus.unlocked) {
      setKeychainMigrationState(
        upstreamMigrationStatusToMigrationState[upstreamMigrationStatus]
      );
    }
  }, [keychainStatus, migrate, upstreamMigrationStatus]);

  useEffect(() => {
    if (keychainMigrationState === KeychainMigrationState.MigrationInProgress) {
      startOrResumeMigration().then(() => {
        setUpstreamMigrationStatus(MigrationStatus.Migrated);
      });
    }
  }, [
    enclaveToken,
    keychainMigrationState,
    setUpstreamMigrationStatus,
    startOrResumeMigration,
  ]);

  const daysLeftToMigrate = differenceInCalendarDays(
    migrationDeadline,
    new Date()
  );

  const onSetEncalveToken = useCallback(
    (token: string) => {
      setEnclaveToken(token);
      setKeychainMigrationState(KeychainMigrationState.MigrationInProgress);
    },
    [setEnclaveToken]
  );

  return (
    <>
      <MigrationAvailableDialog
        onClose={() => {
          setKeychainMigrationState(
            KeychainMigrationState.MigrationRequiredWarning
          );
        }}
        onConfirm={() => {
          setKeychainMigrationState(KeychainMigrationState.MFAPrompt);
        }}
        open={keychainMigrationState === KeychainMigrationState.PromptMigration}
        postponeable={daysLeftToMigrate > 0}
      />
      <EnclaveMfaTokenDialog
        open={keychainMigrationState === KeychainMigrationState.MFAPrompt}
        setEnclaveToken={onSetEncalveToken}
      />
      <MigrationProgressDialog
        open={
          keychainMigrationState === KeychainMigrationState.MigrationInProgress
        }
        progress={progress ?? 0}
      />
      <MigrationRequiredDialog
        daysLeft={daysLeftToMigrate}
        onClose={() => {
          setKeychainMigrationState(KeychainMigrationState.NoMigration);
        }}
        onConfirm={() => {
          setKeychainMigrationState(KeychainMigrationState.MFAPrompt);
        }}
        open={
          keychainMigrationState ===
          KeychainMigrationState.MigrationRequiredWarning
        }
      />
      {children}
    </>
  );
};
export default KeychainMigrationWrapper;
